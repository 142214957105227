/* Estilos para la barra de navegación */

.parallax_background{
 /* background-image: url('./assets/img/b2.jpg');*/
 background-color: #f7f7f7;
  background-size: cover;
  background-attachment: fixed;
  
  height:100%;
  opacity: 0.8;

}
.cards_home{
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.189);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  text-align: center;

}

.cardHead{
  margin-top: 50px;
}

.CardHM{
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.189);
box-shadow: 0 8px 32px 0 rgba(188, 48, 216, 0.37);
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba(215, 76, 217, 0.18);
text-align: center;
}
.CardHMain {


  background: rgba(255, 255, 255, 0.189);
box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
text-align: center;
}
.imgWelcome{
  align-self: flex-end;
  margin: 20px;
  height: 380px;
  width: 400px;
}
.iconsbuscar{
  height: 20px;
  width: 20px;
  margin: 5px;
}
.iconWdev{
  height: 150px;
  width: 150px;
}
.btnsearch{
  background-color: #ffffff;
  border: 3;
  box-shadow: 0 4px 16px 0 rgba(187, 19, 217, 0.37);
  border-color: #aa14c8ac;
  

}
.txtTitle_Desc_serv{
  color: #aa14c8ac;
  margin-top: 30px;   
  font-weight: 500;
}
.txtDesc_serv{
  margin: 30px;
  font-weight: 500;
}
.CMainCarr {

  background: rgba(0, 0, 0, 0.551);
box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
text-align: center;
}

.nav_link_txt {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgcarro{
  height: 450px;
  width: 1200px;
  border-radius: 32px;
}
.txtBrand{
  color: #ba52e7ca;
  margin: 18px;

  text-align: center;
  
}
.imgcll{
  width: 700px;
  height: 700px;
  border-radius: 30px;
  margin: 20px;
  align-self: center;
}
.nav_link_txt img {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.nav-link {
  color: #f7f7f7;
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  padding: 10px;
  font-weight: 600;
}

.nav-link:hover {
  background-color: #aa14c8ac;
  border-radius: 9px;
  color: #f7f7f7;
}

.icon_main {
  width: 20px;
  height: 20px;
}

/* Estilos responsivos */
@media (max-width: 576px) {
  .CardHMain {
    flex-direction: column;
  }

  .nav_link_txt,
  .nav-link {
    text-align: left;
    padding: 15px;
  }
}

.imgcard{
  height: 300px;
  width: 450px;
  border-radius: 32px;
}
